<template>
  <section>
    <div v-if="!isLoaded" class="h-screen vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" />
    </div>
    <!-- Basic Settings -->

    <div class="mx-auto mt-3 lg:mt-10 md:mt-5 max-w-6xl" v-if="isLoaded">
      <!-- Personal ID Verification -->

      <div class="flex justify-between items-center mt-10">
        <div class="flex items-center font-bold text-22px text-oCharcoal">
          <div class="id-card-css-icon" />
          <span class="pl-3">{{
            $t(
              'components.settingsManagement.security.headline.personalIdVerification'
            )
          }}</span>
        </div>
      </div>
      <div
        :key="`personalIDVerification_${counter}`"
        class=" mt-3 pt-5 border-t-2"
      >
        <SmartToggleInput
          :key="`personalIDVerification_${counter + 0}`"
          :label="personalIDVerificationSettings[0].label"
          :description="personalIDVerificationSettings[0].description"
          v-model="personal[`${personalIDVerificationSettings[0].variable}`]"
          :onText="personalIDVerificationSettings[0].onText"
          :offText="personalIDVerificationSettings[0].offText"
          :onValue="personalIDVerificationSettings[0].onValue"
          :offValue="personalIDVerificationSettings[0].offValue"
          @save="
            onSave(`${personalIDVerificationSettings[0].variable}`, $event)
          "
        />

        <SmartAppInput
          v-if="personal.doc_verification_required_for_trip"
          :key="`personalIDVerification_${counter + 1}`"
          :type="personalIDVerificationSettings[1].type"
          :label="personalIDVerificationSettings[1].label"
          :description="personalIDVerificationSettings[1].description"
          :placeholder="personalIDVerificationSettings[1].placeholder"
          :options="personalIDVerificationSettings[1].options"
          :isMultipleChoice="true"
          multipleChoiceType="vehicleType"
          v-model="personal[`${personalIDVerificationSettings[1].variable}`]"
          @save="
            onSave(
              `${personalIDVerificationSettings[1].variable}`,
              JSON.stringify($event)
            )
          "
        />

        <SmartToggleInput
          v-if="personal.doc_verification_required_for_trip"
          :key="`personalIDVerification_${counter + 2}`"
          :label="personalIDVerificationSettings[2].label"
          :description="personalIDVerificationSettings[2].description"
          :onText="personalIDVerificationSettings[2].onText"
          :offText="personalIDVerificationSettings[2].offText"
          :onValue="personalIDVerificationSettings[2].onValue"
          :offValue="personalIDVerificationSettings[2].offValue"
          v-model="personal[`${personalIDVerificationSettings[2].variable}`]"
          @save="
            onSave(`${personalIDVerificationSettings[2].variable}`, $event)
          "
        />
        <SmartToggleInput
          v-if="personal.doc_verification_required_for_trip"
          :key="`personalIDVerification_${counter + 3}`"
          :label="personalIDVerificationSettings[3].label"
          :description="personalIDVerificationSettings[3].description"
          :onText="personalIDVerificationSettings[3].onText"
          :offText="personalIDVerificationSettings[3].offText"
          :onValue="personalIDVerificationSettings[3].onValue"
          :offValue="personalIDVerificationSettings[3].offValue"
          v-model="personal[`${personalIDVerificationSettings[3].variable}`]"
          @save="
            onSave(`${personalIDVerificationSettings[3].variable}`, $event)
          "
        />
        <SmartToggleInput
          v-if="
            personal.doc_verification_required_for_trip &&
              personal.driver_id_required
          "
          :key="`personalIDVerification_${counter + 4}`"
          :label="personalIDVerificationSettings[4].label"
          :description="personalIDVerificationSettings[4].description"
          :onText="personalIDVerificationSettings[4].onText"
          :offText="personalIDVerificationSettings[4].offText"
          :onValue="personalIDVerificationSettings[4].onValue"
          :offValue="personalIDVerificationSettings[4].offValue"
          v-model="personal[`${personalIDVerificationSettings[4].variable}`]"
          @save="
            onSave(`${personalIDVerificationSettings[4].variable}`, $event)
          "
        />
        <SmartToggleInput
          v-if="
            personal.doc_verification_required_for_trip &&
              personal.driver_id_required
          "
          :key="`personalIDVerification_${counter + 5}`"
          :label="personalIDVerificationSettings[5].label"
          :description="personalIDVerificationSettings[5].description"
          :onText="personalIDVerificationSettings[5].onText"
          :offText="personalIDVerificationSettings[5].offText"
          :onValue="personalIDVerificationSettings[5].onValue"
          :offValue="personalIDVerificationSettings[5].offValue"
          v-model="personal[`${personalIDVerificationSettings[5].variable}`]"
          @save="
            onSave(`${personalIDVerificationSettings[5].variable}`, $event)
          "
        />
        <SmartToggleInput
          v-if="
            personal.doc_verification_required_for_trip &&
              personal.driver_id_required
          "
          :key="`personalIDVerification_${counter + 6}`"
          :label="personalIDVerificationSettings[6].label"
          :description="personalIDVerificationSettings[6].description"
          :onText="personalIDVerificationSettings[6].onText"
          :offText="personalIDVerificationSettings[6].offText"
          :onValue="personalIDVerificationSettings[6].onValue"
          :offValue="personalIDVerificationSettings[6].offValue"
          v-model="personal[`${personalIDVerificationSettings[6].variable}`]"
          @save="
            onSave(`${personalIDVerificationSettings[6].variable}`, $event)
          "
        />
        <SmartToggleInput
          v-if="personal.doc_verification_required_for_trip"
          :key="`personalIDVerification_${counter + 7}`"
          :label="personalIDVerificationSettings[7].label"
          :description="personalIDVerificationSettings[7].description"
          :onText="personalIDVerificationSettings[7].onText"
          :offText="personalIDVerificationSettings[7].offText"
          :onValue="personalIDVerificationSettings[7].onValue"
          :offValue="personalIDVerificationSettings[7].offValue"
          v-model="personal[`${personalIDVerificationSettings[7].variable}`]"
          @save="
            onSave(`${personalIDVerificationSettings[7].variable}`, $event)
          "
        />
        <SmartToggleInput
          v-if="personal.doc_verification_required_for_trip"
          :key="`personalIDVerification_${counter + 8}`"
          :label="personalIDVerificationSettings[8].label"
          :description="personalIDVerificationSettings[8].description"
          :onText="personalIDVerificationSettings[8].onText"
          :offText="personalIDVerificationSettings[8].offText"
          :onValue="personalIDVerificationSettings[8].onValue"
          :offValue="personalIDVerificationSettings[8].offValue"
          v-model="personal[`${personalIDVerificationSettings[8].variable}`]"
          @save="
            onSave(`${personalIDVerificationSettings[8].variable}`, $event)
          "
        />
        <SmartToggleInput
          :key="`personalIDVerification_${counter + 9}`"
          :label="personalIDVerificationSettings[9].label"
          :description="personalIDVerificationSettings[9].description"
          :onText="personalIDVerificationSettings[9].onText"
          :offText="personalIDVerificationSettings[9].offText"
          :onValue="personalIDVerificationSettings[9].onValue"
          :offValue="personalIDVerificationSettings[9].offValue"
          v-model="personal[`${personalIDVerificationSettings[9].variable}`]"
          @save="
            onSave(`${personalIDVerificationSettings[9].variable}`, $event)
          "
        />
      </div>
      <!-- ID Re-verification -->
      <div class="flex justify-between items-center mt-10">
        <div class="flex items-center font-bold text-22px text-oCharcoal">
          <div class="id-card-css-icon" />
          <span class="pl-3">{{
            $t(
              'components.settingsManagement.security.headline.idReverification'
            )
          }}</span>
        </div>
      </div>
      <div :key="`iDReVerification_${counter}`" class=" mt-3 pt-5 border-t-2">
        <SmartToggleInput
          :key="`iDReVerification_${counter + 0}`"
          :label="iDReVerificationSettings[0].label"
          :description="iDReVerificationSettings[0].description"
          :placeholder="iDReVerificationSettings[0].placeholder"
          :onText="iDReVerificationSettings[0].onText"
          :offText="iDReVerificationSettings[0].offText"
          :onValue="iDReVerificationSettings[0].onValue"
          :offValue="iDReVerificationSettings[0].offValue"
          v-model="personalRe[`${iDReVerificationSettings[0].variable}`]"
          @save="onSave(`${iDReVerificationSettings[0].variable}`, $event)"
        />
        <SmartAppInput
          v-if="personalRe.id_re_verification_enabled"
          :key="`iDReVerification_${counter + 1}`"
          :rowId="`iDReVerification_${1}`"
          :type="iDReVerificationSettings[1].type"
          :label="iDReVerificationSettings[1].label"
          :description="iDReVerificationSettings[1].description"
          :placeholder="iDReVerificationSettings[1].placeholder"
          :isEdit="iDReVerificationSettings[1].isEdit"
          :options="iDReVerificationSettings[1].options"
          v-model="personalRe[`${iDReVerificationSettings[1].variable}`]"
          @save="onSave(`${iDReVerificationSettings[1].variable}`, $event)"
        />
        <SmartAppInput
          v-if="personalRe.id_re_verification_enabled"
          :key="`iDReVerification_${counter + 2}`"
          :rowId="`iDReVerification_${2}`"
          :type="iDReVerificationSettings[2].type"
          :label="iDReVerificationSettings[2].label"
          :description="iDReVerificationSettings[2].description"
          :placeholder="iDReVerificationSettings[2].placeholder"
          :isEdit="iDReVerificationSettings[2].isEdit"
          :options="iDReVerificationSettings[2].options"
          v-model="personalRe[`${iDReVerificationSettings[2].variable}`]"
          @save="onSave(`${iDReVerificationSettings[2].variable}`, $event)"
        />
      </div>

      <!-- other Verification -->
      <div class="flex justify-between items-center mt-10">
        <div class="flex items-center font-bold text-22px text-oCharcoal">
          <div class="id-card-css-icon" />
          <span class="pl-3">{{
            $t(
              'components.settingsManagement.security.headline.otherVerification'
            )
          }}</span>
        </div>
      </div>
      <div :key="`otherVerification_${counter}`" class=" mt-3 pt-5 border-t-2">
        <SmartToggleInput
          :key="`otherVerification_${counter + 0}`"
          :label="otherVerificationSettings[0].label"
          :description="otherVerificationSettings[0].description"
          :placeholder="otherVerificationSettings[0].placeholder"
          :onText="otherVerificationSettings[0].onText"
          :offText="otherVerificationSettings[0].offText"
          :onValue="otherVerificationSettings[0].onValue"
          :offValue="otherVerificationSettings[0].offValue"
          v-model="other[`${otherVerificationSettings[0].variable}`]"
          @save="onSave(`${otherVerificationSettings[0].variable}`, $event)"
        />
        <SmartToggleInput
          :key="`otherVerification_${counter + 1}`"
          :label="otherVerificationSettings[1].label"
          :description="otherVerificationSettings[1].description"
          :placeholder="otherVerificationSettings[1].placeholder"
          :onText="otherVerificationSettings[1].onText"
          :offText="otherVerificationSettings[1].offText"
          :onValue="otherVerificationSettings[1].onValue"
          :offValue="otherVerificationSettings[1].offValue"
          v-model="other[`${otherVerificationSettings[1].variable}`]"
          @save="onSave(`${otherVerificationSettings[1].variable}`, $event)"
        />
        <SmartAppInput
          v-if="other.is_age_verification_required"
          :key="`otherVerification_${counter + 2}`"
          :rowId="`otherVerification_${2}`"
          :type="otherVerificationSettings[2].type"
          :label="otherVerificationSettings[2].label"
          :description="otherVerificationSettings[2].description"
          :placeholder="otherVerificationSettings[2].placeholder"
          :isEdit="otherVerificationSettings[2].isEdit"
          :options="otherVerificationSettings[2].options"
          v-model="other[`${otherVerificationSettings[2].variable}`]"
          :haveUnitText="true"
          unitText="Years"
          @save="onSave(`${otherVerificationSettings[2].variable}`, $event)"
        />

        <SmartToggleInput
          :key="`otherVerification_${counter + 3}`"
          :label="otherVerificationSettings[3].label"
          :description="otherVerificationSettings[3].description"
          :placeholder="otherVerificationSettings[3].placeholder"
          :onText="otherVerificationSettings[3].onText"
          :offText="otherVerificationSettings[3].offText"
          :onValue="otherVerificationSettings[3].onValue"
          :offValue="otherVerificationSettings[3].offValue"
          v-model="other[`${otherVerificationSettings[3].variable}`]"
          @save="onSave(`${otherVerificationSettings[3].variable}`, $event)"
        />
      </div>

      <!-- security -->
      <div class="flex justify-between items-center mt-10">
        <div class="flex items-center font-bold text-22px text-oCharcoal">
          <div class="id-card-css-icon" />
          <span class="pl-3">{{
            $t('components.settingsManagement.security.headline.security')
          }}</span>
        </div>
      </div>
      <div :key="`security_${counter}`" class=" mt-3 pt-5 border-t-2">
        <SmartAppInput
          :key="`security_${counter + 0}`"
          :rowId="`security_${0}`"
          :type="securitySettings[0].type"
          :label="securitySettings[0].label"
          :description="securitySettings[0].description"
          :placeholder="securitySettings[0].placeholder"
          :isEdit="securitySettings[0].isEdit"
          :options="securitySettings[0].options"
          v-model="security[`${securitySettings[0].variable}`]"
          :haveUnitText="securitySettings[0].haveUnitText"
          :unitText="securitySettings[0].unitText"
          :unitWidthVariant="securitySettings[0].unitWidthVariant"
          @save="onSave(`${securitySettings[0].variable}`, $event)"
        />
        <SmartToggleInput
          :key="`security_${counter + 1}`"
          :label="securitySettings[1].label"
          :description="securitySettings[1].description"
          :placeholder="securitySettings[1].placeholder"
          :onText="securitySettings[1].onText"
          :offText="securitySettings[1].offText"
          :onValue="securitySettings[1].onValue"
          :offValue="securitySettings[1].offValue"
          v-model="security[`${securitySettings[1].variable}`]"
          @save="onSave(`${securitySettings[1].variable}`, $event)"
        />
        <SmartToggleInput
          :key="`security_${counter + 2}`"
          :label="securitySettings[2].label"
          :description="securitySettings[2].description"
          :placeholder="securitySettings[2].placeholder"
          :onText="securitySettings[2].onText"
          :offText="securitySettings[2].offText"
          :onValue="securitySettings[2].onValue"
          :offValue="securitySettings[2].offValue"
          v-model="security[`${securitySettings[2].variable}`]"
          @save="onSave(`${securitySettings[2].variable}`, $event)"
        />
        <SmartToggleInput
          :key="`security_${counter + 3}`"
          :label="securitySettings[3].label"
          :description="securitySettings[3].description"
          :placeholder="securitySettings[3].placeholder"
          :onText="securitySettings[3].onText"
          :offText="securitySettings[3].offText"
          :onValue="securitySettings[3].onValue"
          :offValue="securitySettings[3].offValue"
          v-model="security[`${securitySettings[3].variable}`]"
          @save="onSave(`${securitySettings[3].variable}`, $event)"
        />
        <SmartToggleInput
          :key="`security_${counter + 4}`"
          :label="securitySettings[4].label"
          :description="securitySettings[4].description"
          :placeholder="securitySettings[4].placeholder"
          :onText="securitySettings[4].onText"
          :offText="securitySettings[4].offText"
          :onValue="securitySettings[4].onValue"
          :offValue="securitySettings[4].offValue"
          v-model="security[`${securitySettings[4].variable}`]"
          @save="onSave(`${securitySettings[4].variable}`, $event)"
        />
        <SmartAppInput
          v-if="security.should_block_user_for_exceeding_otp_rate_limit"
          :key="`security_${counter + 5}`"
          :rowId="`security_${5}`"
          :type="securitySettings[5].type"
          :label="securitySettings[5].label"
          :description="securitySettings[5].description"
          :placeholder="securitySettings[5].placeholder"
          :isEdit="securitySettings[5].isEdit"
          :options="securitySettings[5].options"
          v-model="security[`${securitySettings[5].variable}`]"
          @save="onSave(`${securitySettings[5].variable}`, $event)"
        />
        <SmartAppInput
          v-if="security.should_block_user_for_exceeding_otp_rate_limit"
          :key="`security_${counter + 6}`"
          :rowId="`security_${6}`"
          :type="securitySettings[6].type"
          :label="securitySettings[6].label"
          :description="securitySettings[6].description"
          :placeholder="securitySettings[6].placeholder"
          :isEdit="securitySettings[6].isEdit"
          :options="securitySettings[6].options"
          v-model="security[`${securitySettings[6].variable}`]"
          :haveUnitText="true"
          :unitText="
            getOtpRateLimitUnitText(security[`${securitySettings[5].variable}`])
          "
          :unitWidthVariant="'large'"
          @save="onSave(`${securitySettings[6].variable}`, $event)"
        />
        <SmartToggleInput
          :key="`security_${counter + 7}`"
          :label="securitySettings[7].label"
          :description="securitySettings[7].description"
          :placeholder="securitySettings[7].placeholder"
          :onText="securitySettings[7].onText"
          :offText="securitySettings[7].offText"
          :onValue="securitySettings[7].onValue"
          :offValue="securitySettings[7].offValue"
          v-model="security[`${securitySettings[7].variable}`]"
          @save="onSave(`${securitySettings[7].variable}`, $event)"
        />
        <SmartAppInput
          v-if="security.should_block_sms_for_consecutive_unregistered_attempts"
          :key="`security_${counter + 8}`"
          :rowId="`security_${8}`"
          :type="securitySettings[8].type"
          :label="securitySettings[8].label"
          :description="securitySettings[8].description"
          :placeholder="securitySettings[8].placeholder"
          :isEdit="securitySettings[8].isEdit"
          :options="securitySettings[8].options"
          v-model="security[`${securitySettings[8].variable}`]"
          @save="onSave(`${securitySettings[8].variable}`, $event)"
        />
        <SmartAppInput
          v-if="security.should_block_sms_for_consecutive_unregistered_attempts"
          :key="`security_${counter + 9}`"
          :rowId="`security_${9}`"
          :type="securitySettings[9].type"
          :label="securitySettings[9].label"
          :description="securitySettings[9].description"
          :placeholder="securitySettings[9].placeholder"
          :isEdit="securitySettings[9].isEdit"
          :options="securitySettings[9].options"
          v-model="security[`${securitySettings[9].variable}`]"
          @save="onSave(`${securitySettings[9].variable}`, $event)"
        />
        <SmartAppInput
          v-if="security.should_block_sms_for_consecutive_unregistered_attempts"
          :key="`security_${counter + 10}`"
          :rowId="`security_${10}`"
          :type="securitySettings[10].type"
          :label="securitySettings[10].label"
          :description="securitySettings[10].description"
          :placeholder="securitySettings[10].placeholder"
          :isEdit="securitySettings[10].isEdit"
          :options="securitySettings[10].options"
          v-model="security[`${securitySettings[10].variable}`]"
          @save="onSave(`${securitySettings[10].variable}`, $event)"
        />
      </div>
    </div>
  </section>
</template>

<script>
import SmartAppInput from '@/components/form/SmartAppInput.vue'
import SmartToggleInput from '@/components/form/SmartToggleInput.vue'
import { BasicSettingConfig } from '@/config/SettingsConfig'
import { EventBus } from '@/utils'

export default {
  name: 'SecuritySettings',
  components: {
    SmartAppInput,
    SmartToggleInput,
  },
  data() {
    return {
      isLoaded: false,
      counter: 0,
      currentSettingsGroup: null,
      vehicleTypeMap: {
        P: {
          text: 'Scooter',
          value: 'P',
          displayText: 'Scooter',
        },
        PP: {
          text: 'Scooter Pro',
          value: 'PP',
          displayText: 'Scooter Pro',
        },
        E: {
          text: 'E-Bike',
          value: 'E',
          displayText: 'E-Bike',
        },
        S: {
          text: 'Bike',
          value: 'S',
          displayText: 'Bike',
        },
        C: {
          text: 'SuperCoco',
          value: 'C',
          displayText: 'SuperCoco',
        },
        K: {
          text: 'Kenota',
          value: 'K',
          displayText: 'Kenota',
        },
        M: {
          text: 'Moped',
          value: 'M',
          displayText: 'Moped',
        },
      },
      personal: {
        driver_id_required: 0,
        personal_id_required: 0,
        doc_verification_required: 1,
        doc_verification_required_for_trip: 1,
        auto_verification: 0,
        driver_id_back_pic_required: false,
        driver_id_expiration_date_required: false,
        driver_id_issue_date_required: false,
        vehicle_types_for_doc_verification: [],
        selfie_required: false,
      },
      other: {
        age_verification_minimum_age: 0,
        is_age_verification_required: false,
        is_rider_address_required: false,
        is_gender_verification_required: false,
      },
      personalRe: {
        id_re_verification_enabled: false,
        id_re_verification_period_type: null,
        id_re_verification_period_value: 0,
      },
      security: {
        user_topup_daily_limit: 0,
        should_block_sms_for_consecutive_unregistered_attempts: false,
        unregistered_sms_streak_expire_time_minutes: null,
        number_of_consecutive_unregistered_sms_allowed: null,
        sms_block_time_minutes: null,
        should_block_user_for_exceeding_otp_rate_limit: null,
        otp_rate_limit: null,
        otp_rate_mode: null,
        is_wallet_payment_enabled: null,
        is_cash_payment_enabled: null,
        is_on_ride_payment_enabled: null,
      },
      inPlaceEditingState: {
        basic: false,
        personal: false,
        other: false,
        personalRe: false,
      },

      personalIDVerificationSettings: [
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.security.personalIdVerification.tripRequiresDocumentVerification.title'
          ),
          description: this.$t(
            'components.settingsManagement.security.personalIdVerification.tripRequiresDocumentVerification.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'doc_verification_required_for_trip',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.security.personalIdVerification.vehicleTypes.title'
          ),
          description: this.$t(
            'components.settingsManagement.security.personalIdVerification.vehicleTypes.description'
          ),
          options: [
            {
              text: 'Scooter',
              value: 'P',
            },
            {
              text: 'Scooter Pro',
              value: 'PP',
            },
            {
              text: 'E-Bike',
              value: 'E',
            },
            {
              text: 'Bike',
              value: 'S',
            },
            {
              text: 'SuperCoco',
              value: 'C',
            },
            {
              text: 'Kenota',
              value: 'K',
            },
            {
              text: 'Moped',
              value: 'M',
            },
            {
              text: 'All',
              value: 'ALL',
            },
          ],
          variable: 'vehicle_types_for_doc_verification',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.security.personalIdVerification.documentVerificationRequired.title'
          ),
          description: this.$t(
            'components.settingsManagement.security.personalIdVerification.documentVerificationRequired.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'doc_verification_required',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.security.personalIdVerification.driverId.title'
          ),
          description: this.$t(
            'components.settingsManagement.security.personalIdVerification.driverId.description'
          ),
          onValue: true,
          onText: this.$t(
            'components.settingsManagement.commonAction.required'
          ),
          offValue: false,
          offText: this.$t(
            'components.settingsManagement.commonAction.notRequired'
          ),

          variable: 'driver_id_required',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.security.personalIdVerification.driverIdBackPhoto.title'
          ),
          description: this.$t(
            'components.settingsManagement.security.personalIdVerification.driverIdBackPhoto.description'
          ),
          onValue: true,
          onText: this.$t(
            'components.settingsManagement.commonAction.required'
          ),
          offValue: false,
          offText: this.$t(
            'components.settingsManagement.commonAction.notRequired'
          ),
          variable: 'driver_id_back_pic_required',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.security.personalIdVerification.driverIdExpirationDate.title'
          ),
          description: this.$t(
            'components.settingsManagement.security.personalIdVerification.driverIdExpirationDate.description'
          ),

          onValue: true,
          onText: this.$t(
            'components.settingsManagement.commonAction.required'
          ),
          offValue: false,
          offText: this.$t(
            'components.settingsManagement.commonAction.notRequired'
          ),
          variable: 'driver_id_expiration_date_required',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.security.personalIdVerification.driverIdIssueDate.title'
          ),
          description: this.$t(
            'components.settingsManagement.security.personalIdVerification.driverIdIssueDate.description'
          ),

          onValue: true,
          onText: this.$t(
            'components.settingsManagement.commonAction.required'
          ),
          offValue: false,
          offText: this.$t(
            'components.settingsManagement.commonAction.notRequired'
          ),
          variable: 'driver_id_issue_date_required',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.security.personalIdVerification.personalId.title'
          ),
          description: this.$t(
            'components.settingsManagement.security.personalIdVerification.personalId.description'
          ),

          onValue: true,
          onText: this.$t(
            'components.settingsManagement.commonAction.required'
          ),
          offValue: false,
          offText: this.$t(
            'components.settingsManagement.commonAction.notRequired'
          ),
          variable: 'personal_id_required',
          isEdit: false,
        },

        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.security.personalIdVerification.selfie.title'
          ),
          description: this.$t(
            'components.settingsManagement.security.personalIdVerification.selfie.description'
          ),

          onValue: true,
          onText: this.$t(
            'components.settingsManagement.commonAction.required'
          ),
          offValue: false,
          offText: this.$t(
            'components.settingsManagement.commonAction.notRequired'
          ),
          variable: 'selfie_required',
          isEdit: false,
        },

        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.security.personalIdVerification.autoIdVerification.title'
          ),
          description: this.$t(
            'components.settingsManagement.security.personalIdVerification.autoIdVerification.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.on'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.off'),
          variable: 'auto_verification',
          isEdit: false,
        },
      ],
      iDReVerificationSettings: [
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.security.iDReVerificationSettings.reVerificationEnabled.title'
          ),
          description: this.$t(
            'components.settingsManagement.security.iDReVerificationSettings.reVerificationEnabled.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'id_re_verification_enabled',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.security.iDReVerificationSettings.reVerificationNotifyIntervalType.title'
          ),
          description: this.$t(
            'components.settingsManagement.security.iDReVerificationSettings.reVerificationNotifyIntervalType.description'
          ),
          options: [
            { value: 'MINUTE', text: 'Minute' },
            { value: 'HOUR', text: 'Hour' },
            { value: 'DAY', text: 'Day' },
            { value: 'MONTH', text: 'Month' },
            { value: 'YEAR', text: 'Year' },
          ],
          variable: 'id_re_verification_period_type',
          isEdit: false,
        },
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.security.iDReVerificationSettings.reVerificationNotifyIntervalValue.title'
          ),
          description: this.$t(
            'components.settingsManagement.security.iDReVerificationSettings.reVerificationNotifyIntervalValue.description'
          ),
          placeholder: 'e.g 2',
          variable: 'id_re_verification_period_value',
          isEdit: false,
        },
      ],
      otherVerificationSettings: [
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.security.otherVerificationSettings.genderVerification.title'
          ),
          description: this.$t(
            'components.settingsManagement.security.otherVerificationSettings.genderVerification.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'is_gender_verification_required',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.security.otherVerificationSettings.ageVerification.title'
          ),
          description: this.$t(
            'components.settingsManagement.security.otherVerificationSettings.ageVerification.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'is_age_verification_required',
          isEdit: false,
        },
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.security.otherVerificationSettings.minimumAgeVerification.title'
          ),
          description: this.$t(
            'components.settingsManagement.security.otherVerificationSettings.minimumAgeVerification.description'
          ),
          placeholder: 'e.g 14',
          variable: 'age_verification_minimum_age',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.security.otherVerificationSettings.addressVerification.title'
          ),
          description: this.$t(
            'components.settingsManagement.security.otherVerificationSettings.addressVerification.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'is_rider_address_required',
          isEdit: false,
        },
      ],
      securitySettings: [
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.security.securitySettings.dailySuccessfulTopupLimit.title'
          ),
          description: this.$t(
            'components.settingsManagement.security.securitySettings.dailySuccessfulTopupLimit.description'
          ),
          placeholder: 'e.g 2',
          variable: 'user_topup_daily_limit',
          haveUnitText: true,
          unitText: 'Per Day',
          unitWidthVariant: 'large',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.security.securitySettings.enableWalletPaymentMethod.title'
          ),
          description: this.$t(
            'components.settingsManagement.security.securitySettings.enableWalletPaymentMethod.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'is_wallet_payment_enabled',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.security.securitySettings.enableCashPaymentMethod.title'
          ),
          description: this.$t(
            'components.settingsManagement.security.securitySettings.enableCashPaymentMethod.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'is_cash_payment_enabled',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.security.securitySettings.enableRidePayment.title'
          ),
          description: this.$t(
            'components.settingsManagement.security.securitySettings.enableRidePayment.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'is_on_ride_payment_enabled',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.security.securitySettings.enableOtpRateLimitFraudSmsActivity.title'
          ),
          description: this.$t(
            'components.settingsManagement.security.securitySettings.enableOtpRateLimitFraudSmsActivity.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'should_block_user_for_exceeding_otp_rate_limit',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.security.securitySettings.otpRateUnit.title'
          ),
          description: this.$t(
            'components.settingsManagement.security.securitySettings.otpRateUnit.description'
          ),
          options: [
            { value: 's', text: 'Per Second' },
            { value: 'm', text: 'Per Minute' },
            { value: 'h', text: 'Per Hour' },
          ],
          variable: 'otp_rate_mode',
          isEdit: false,
        },
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.security.securitySettings.otpRateLimit.title'
          ),
          description: this.$t(
            'components.settingsManagement.security.securitySettings.otpRateLimit.description'
          ),
          placeholder: 'e.g 4',
          variable: 'otp_rate_limit',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.security.securitySettings.allowBlockUnregisteredSMSRequests.title'
          ),
          description: this.$t(
            'components.settingsManagement.security.securitySettings.allowBlockUnregisteredSMSRequests.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'should_block_sms_for_consecutive_unregistered_attempts',
          isEdit: false,
        },
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.security.securitySettings.durationUnregisteredSMSRequests.title'
          ),
          description: this.$t(
            'components.settingsManagement.security.securitySettings.durationUnregisteredSMSRequests.description'
          ),
          placeholder: 'e.g. 10',
          variable: 'unregistered_sms_streak_expire_time_minutes',
          isEdit: false,
        },
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.security.securitySettings.numberSMSCreditsUnregisteredSMSRequests.title'
          ),
          description: this.$t(
            'components.settingsManagement.security.securitySettings.numberSMSCreditsUnregisteredSMSRequests.description'
          ),
          placeholder: 'e.g. 3',
          variable: 'number_of_consecutive_unregistered_sms_allowed',
          isEdit: false,
        },
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.security.securitySettings.blockingPeriodLimitingSMSRequests.title'
          ),
          description: this.$t(
            'components.settingsManagement.security.securitySettings.blockingPeriodLimitingSMSRequests.description'
          ),
          placeholder: 'e.g. 60',
          variable: 'sms_block_time_minutes',
          isEdit: false,
        },
      ],
    }
  },
  computed: {
    orgId() {
      return this.$store.getters['auth/organizationInfo'].id || null
    },
  },
  watch: {
    currentSettingsGroup: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val === 'basic') {
          this.personalIDVerificationSettings = this.personalIDVerificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )

          this.iDReVerificationSettings = this.iDReVerificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )

          this.otherVerificationSettings = this.otherVerificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )

          this.securitySettings = this.securitySettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }
        if (val === 'personalIDVerification') {
          this.iDReVerificationSettings = this.iDReVerificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )

          this.otherVerificationSettings = this.otherVerificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )

          this.securitySettings = this.securitySettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }
        if (val === 'iDReVerification') {
          this.personalIDVerificationSettings = this.personalIDVerificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )

          this.otherVerificationSettings = this.otherVerificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )

          this.securitySettings = this.securitySettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }
        if (val === 'otherVerification') {
          this.personalIDVerificationSettings = this.personalIDVerificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )

          this.iDReVerificationSettings = this.iDReVerificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )

          this.securitySettings = this.securitySettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }

        if (val === 'security') {
          this.personalIDVerificationSettings = this.personalIDVerificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )

          this.iDReVerificationSettings = this.iDReVerificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )

          this.otherVerificationSettings = this.otherVerificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )
        }
      },
    },
  },
  async created() {
    this.$http
      .get(BasicSettingConfig.api.index(this.orgId))
      .then((res) => {
        console.log('settings', res.data)
        let data = res.data

        // personal
        this.personal.driver_id_required = data.driver_id_required
        this.personal.driver_id_back_pic_required =
          data.driver_id_back_pic_required
        this.personal.driver_id_expiration_date_required =
          data.driver_id_expiration_date_required
        this.personal.driver_id_issue_date_required =
          data.driver_id_issue_date_required
        this.personal.vehicle_types_for_doc_verification = this.getVehicleTypes(
          data.vehicle_types_for_doc_verification
        )
        this.personal.selfie_required = data.selfie_required
        this.personal.personal_id_required = data.personal_id_required
        this.personal.doc_verification_required = data.doc_verification_required
        this.personal.doc_verification_required_for_trip =
          data.doc_verification_required_for_trip
        this.personal.auto_verification = data.auto_verification
        this.personalRe.id_re_verification_enabled =
          data.id_re_verification_enabled
        this.personalRe.id_re_verification_period_type =
          data.id_re_verification_period_type
        this.personalRe.id_re_verification_period_value =
          data.id_re_verification_period_value
        this.other.is_gender_verification_required =
          data.is_gender_verification_required
        this.other.is_age_verification_required =
          data.is_age_verification_required

        this.other.age_verification_minimum_age =
          data.age_verification_minimum_age
        this.other.is_rider_address_required = data.is_rider_address_required
        this.security.user_topup_daily_limit = data.user_topup_daily_limit
        this.security.should_block_user_for_exceeding_otp_rate_limit =
          data.should_block_user_for_exceeding_otp_rate_limit
        this.security.otp_rate_limit = data.otp_rate_limit
        this.security.otp_rate_mode = data.otp_rate_mode
        this.security.should_block_sms_for_consecutive_unregistered_attempts =
          data.should_block_sms_for_consecutive_unregistered_attempts
        this.security.unregistered_sms_streak_expire_time_minutes =
          data.unregistered_sms_streak_expire_time_minutes
        this.security.number_of_consecutive_unregistered_sms_allowed =
          data.number_of_consecutive_unregistered_sms_allowed
        this.security.sms_block_time_minutes = data.sms_block_time_minutes
        this.security.is_wallet_payment_enabled = data.is_wallet_payment_enabled
        this.security.is_cash_payment_enabled = data.is_cash_payment_enabled
        this.security.is_on_ride_payment_enabled =
          data.is_on_ride_payment_enabled

        this.isLoaded = true
      })
      .catch((err) => {
        console.log('settignsErr', err)
      })
  },
  mounted() {
    EventBus.$on('open-edit', (id) => {
      const idPrefix = id.split('_')[0]

      if (idPrefix === 'personalIDVerification') {
        this.personalIDVerificationSettings = this.personalIDVerificationSettings.map(
          (item, itemIndex) => {
            if (id === `personalIDVerification_${itemIndex}`) {
              return {
                ...item,
                isEdit: true,
              }
            } else {
              return {
                ...item,
                isEdit: false,
              }
            }
          }
        )
        this.currentSettingsGroup = 'personalIDVerification'
      }
      if (idPrefix === 'iDReVerification') {
        this.iDReVerificationSettings = this.iDReVerificationSettings.map(
          (item, itemIndex) => {
            if (id === `iDReVerification_${itemIndex}`) {
              return {
                ...item,
                isEdit: true,
              }
            } else {
              return {
                ...item,
                isEdit: false,
              }
            }
          }
        )
        this.currentSettingsGroup = 'iDReVerification'
      }
      if (idPrefix === 'otherVerification') {
        this.otherVerificationSettings = this.otherVerificationSettings.map(
          (item, itemIndex) => {
            if (id === `otherVerification_${itemIndex}`) {
              return {
                ...item,
                isEdit: true,
              }
            } else {
              return {
                ...item,
                isEdit: false,
              }
            }
          }
        )
        this.currentSettingsGroup = 'otherVerification'
      }

      if (idPrefix === 'security') {
        this.securitySettings = this.securitySettings.map((item, itemIndex) => {
          if (id === `security_${itemIndex}`) {
            return {
              ...item,
              isEdit: true,
            }
          } else {
            return {
              ...item,
              isEdit: false,
            }
          }
        })
        this.currentSettingsGroup = 'security'
      }
    })
  },
  methods: {
    getVehicleTypes(data) {
      console.log('getVehicleTypes', data)
      if (data.length > 0) {
        return data
      } else {
        return ['ALL']
      }
    },
    getOtpRateLimitUnitText(unit) {
      if (unit === 's') {
        return '/Sec'
      }
      if (unit === 'm') {
        return '/Min'
      }
      if (unit === 'h') {
        return '/Hour'
      }
    },
    async onSave(key, val) {
      let toastTitle, toastText

      if (key === 'doc_verification_required') {
        toastTitle = `Document Verification`
        toastText = `Document Verification settings is updated`
      }
      if (key === 'driver_id_required') {
        toastTitle = `Driver ID `
        toastText = `Driver ID settings is updated`
      }
      if (key === 'driver_id_back_pic_required') {
        toastTitle = `Driver ID Back Photo`
        toastText = `Driver ID Back Photo settings is updated`
      }
      if (key === 'driver_id_expiration_date_required') {
        toastTitle = `Driver ID Expiration Date`
        toastText = `Driver ID Expiration Date settings is updated`
      }
      if (key === 'driver_id_issue_date_required') {
        toastTitle = `Driver ID Issue Date`
        toastText = `Driver ID Issue Date settings is updated`
      }
      if (key === 'personal_id_required') {
        toastTitle = `Personal ID`
        toastText = `Personal ID settings is updated`
      }
      if (key === 'doc_verification_required_for_trip') {
        toastTitle = `Trip Requires Document Verification`
        toastText = `Trip Requires Document Verification settings is updated`
      }
      if (key === 'auto_verification') {
        toastTitle = `Auto ID Verification`
        toastText = `Auto ID Verification settings is updated`
      }
      if (key === 'id_re_verification_enabled') {
        toastTitle = `Re-verification Enabled`
        toastText = `Re-verification Enabled settings is updated`
      }
      if (key === 'id_re_verification_period_type') {
        toastTitle = `Re-verification Notify Interval Type`
        toastText = `Re-verification Notify Interval Type settings is updated`
      }
      if (key === 'id_re_verification_period_value') {
        toastTitle = `Re-verification Notify Interval Value`
        toastText = `Re-verification Notify Interval Value is updated`
      }

      if (key === 'is_age_verification_required') {
        toastTitle = `Age Verification`
        toastText = `Age Verification is updated`
      }
      if (key === 'is_rider_address_required') {
        toastTitle = `Address Verification`
        toastText = `Address Verification is updated`
      }

      if (key === 'age_verification_minimum_age') {
        toastTitle = `Age Verification Minimum Age`
        toastText = `Age Verification Minimum Age is updated`
      }
      if (key === 'is_gender_verification_required') {
        toastTitle = `Gender Verification`
        toastText = `Gender Verification is updated`
      }

      if (key === 'user_topup_daily_limit') {
        toastTitle = `Daily Successful Top-up Limit`
        toastText = `Daily Successful Top-up Limit is updated`
      }
      if (key === 'should_block_user_for_exceeding_otp_rate_limit') {
        toastTitle = `Success`
        toastText = `Successfully Updated`
      }
      if (key === 'otp_rate_limit') {
        toastTitle = `Success`
        toastText = `OTP rate limit is updated`
      }
      if (key === 'otp_rate_mode') {
        toastTitle = `Success`
        toastText = `OTP rate mode is updated`
      }
      if (key === 'should_block_sms_for_consecutive_unregistered_attempts') {
        toastTitle = `Success`
        toastText = `Should block sms for consecutive unregistered attempts is updated`
      }
      if (key === 'unregistered_sms_streak_expire_time_minutes') {
        toastTitle = `Success`
        toastText = `Unregistered sms streak expire time minutes is updated`
      }
      if (key === 'number_of_consecutive_unregistered_sms_allowed') {
        toastTitle = `Success`
        toastText = `Number of consecutive unregistered sms allowed is updated`
      }
      if (key === 'sms_block_time_minutes') {
        toastTitle = `Success`
        toastText = `Sms block time minutes is updated`
      }
      if (key === 'is_wallet_payment_enabled') {
        toastTitle = `Success`
        toastText = `Wallet payment status is updated`
      }
      if (key === 'is_cash_payment_enabled') {
        toastTitle = `Success`
        toastText = `Cash payment status is updated`
      }
      if (key === 'is_on_ride_payment_enabled') {
        toastTitle = `Success`
        toastText = `On ride payment status is updated`
      }

      if (key === 'vehicle_types_for_doc_verification') {
        toastTitle = `Success`
        toastText = `Vehicle type is updated`
      }
      if (key === 'selfie_required') {
        toastTitle = `Success`
        toastText = `Selfie status is updated`
      }
      let data = new FormData()
      if (key === 'vehicle_types_for_doc_verification') {
        if (val.includes('ALL')) {
          data.append(key, JSON.stringify([]))
        } else {
          data.append(key, val)
        }
      } else {
        data.append(key, val)
      }

      await this.$http
        .patch(BasicSettingConfig.api.update(this.orgId), data)
        .then((res) => {
          console.log(res)

          this.personalIDVerificationSettings = this.personalIDVerificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )

          this.iDReVerificationSettings = this.iDReVerificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )

          this.otherVerificationSettings = this.otherVerificationSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )
          this.securitySettings = this.securitySettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: toastTitle,
              text: toastText,
            },
            3000
          )
          this.counter++
        })
        .catch((err) => {
          console.log('onSaveError', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: toastTitle,
              text: 'Failed to update settings',
            },
            3000
          )
        })
    },
  },
}
</script>

<style lang="scss">
.id-card-css-icon {
  width: 13.5px;
  height: 22px;
  border-radius: 2px;
  background-color: #16cded;
}
</style>
